import React, { useEffect, useMemo } from "react"
import { Link, graphql } from "gatsby"
import { getImage, StaticImage, GatsbyImage } from "gatsby-plugin-image"
import imagesLoaded from "imagesloaded"
import ScrollMagic from "scrollmagic"
import Layout from "../components/Layout"
import MetaData from "../components/meta"
import Splide from "@splidejs/splide"
import getRedirectUrl from "../utils/getRedirectUrl"

import {
  BoxedContainer,
  Box,
  Flex,
  Grid,
  Heading,
  Paragraph,
  Button,
} from "../elements"
import theme, { padding } from "../styles/theme"
import CardIconTextLink from "../components/CardIconTextLink"

import EmailIcon from "../images/inline-svgs/email.svg"
import RightArrowWhite from "../images/inline-svgs/right-arrow-white.svg"
import heroIllustrationLeft from "../images/home/abstract-blocks-left.svg"
import heroIllustrationRight from "../images/home/abstract-blocks-right.svg"
import loadBalancerIcon from "../images/home/applications/load-balancing.svg"
import apiIcon from "../images/home/applications/api-gateway.svg"
import kubernetesIcon from "../images/home/applications/kubernetes-ingress.svg"
import serviceMeshIcon from "../images/home/applications/mesh.svg"
import downloadsIcon from "../images/home/downloads-icon.svg"
import githubIcon from "../images/home/github-icon.svg"
import contributorsIcon from "../images/home/contributors-icon.svg"
import ctaTraefikEEIcon from "../images/home/cta/traefik-enterprise.svg"
import ctaEnvelopeIcon from "../images/home/cta/envelope-icon.svg"
import enterpriseLogo from "../images/logos/traefik-enterprise-logo--white.svg"
import proxyLogo from "../images/logos/traefik-proxy-logo--white.svg"
import meshLogo from "../images/logos/traefik-mesh-logo--white.svg"

// Import Styles
import "../styles/home.scss"
import "@splidejs/splide/dist/css/splide-core.min.css"

// Sections
import CustomersSection from "../sections/home/Customers"

interface Props {
  data: any
}

const IndexPage = ({ data, location }: Props) => {
  let featured_posts_slide_1 =
    data.featured_posts_1.edges.length > 0
      ? data.featured_posts_1.edges
      : data.latest_blog.edges

  let featured_posts_slide_2 =
    data.featured_posts_2.edges.length > 0
      ? data.featured_posts_2.edges
      : data.latest_resource.edges

  let featured_posts = featured_posts_slide_1.concat(
    featured_posts_slide_2,
    data.featured_posts_3.edges,
    data.featured_posts.edges
  )

  useEffect(() => {
    imagesLoaded(".home-hero", function () {
      document
        .querySelector(".home-hero__illustrations .img-left")
        ?.classList.add("visible")

      document
        .querySelector(".home-hero__illustrations .img-right")
        ?.classList.add("visible")
    })

    // Init featured posts carousel
    try {
      new Splide(".splide", {
        type: "loop",
        perPage: 1,
        arrows: false,
        pagination: true,
        autoplay: true,
        interval: 3500,
        speed: 500,
      }).mount()
    } catch (error) {}

    /* Products Scrolling Effects */
    let controller = new ScrollMagic.Controller()

    // Pin product images container
    let scene_product_images = new ScrollMagic.Scene({
      triggerElement: ".home-products-layout--desktop #home-products-images",
      duration: 950,
      triggerHook: 0.28,
    })
      .setPin(".home-products-layout--desktop #home-products-images")
      .addTo(controller)

    // Traefik Enterprise
    let scene_details_traefikee = new ScrollMagic.Scene({
      triggerElement:
        ".home-products-layout--desktop .products-details-item--traefikee",
      duration: 500,
      triggerHook: 0.5,
    })
      .setClassToggle(
        ".home-products-layout--desktop .products-images-item--traefikee",
        "visible"
      )
      .addTo(controller)

    // Traefik
    let scene_details_traefik = new ScrollMagic.Scene({
      triggerElement:
        ".home-products-layout--desktop .products-details-item--traefik",
      duration: 500,
      triggerHook: 0.5,
    })
      .setClassToggle(
        ".home-products-layout--desktop .products-images-item--traefik",
        "visible"
      )
      .addTo(controller)

    // Mesh
    let scene_details_maesh = new ScrollMagic.Scene({
      triggerElement:
        ".home-products-layout--desktop .products-details-item--maesh",
      triggerHook: 0.5,
    })
      .setClassToggle(
        ".home-products-layout--desktop .products-images-item--maesh",
        "visible"
      )
      .addTo(controller)

    return () => {
      controller.destroy(true)
    }
  }, [])

  return (
    <Layout>
      <MetaData location={location} />
      <div className="home-page">
        {/* Hero */}
        <section className="home-hero">
          <BoxedContainer>
            <Flex
              position="relative"
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              zIndex={1}
            >
              <Box
                width={["190px", null, "240px"]}
                m={["0 auto 25px", null, "0 auto 30px"]}
              >
                <Heading as="h1">
                  <StaticImage
                    src="../images/logos/traefik-labs-logo--white.png"
                    alt="Load Balancer + Kubernetes Ingress + Service Mesh with Traefik and Traefik Mesh"
                    placeholder="none"
                    loading="eager"
                    width={240}
                    objectFit="contain"
                  />
                </Heading>
              </Box>
              <Heading
                as="span"
                variant="lg"
                display="block"
                m="0 auto 18px"
                textAlign="center"
                color="text.inverted.light"
              >
                Makes Networking Boring
              </Heading>

              <Heading
                as="h2"
                variant="sm"
                fontSize={["2rem", null, null, "2.4rem", "2.4rem"]}
                color="#b3bac0"
                fontWeight="normal"
                textAlign="center"
              >
                Cloud-Native Networking Stack That Just Works
              </Heading>

              <Box mt={["40px"]} width="100%" className="home-hero__form">
                <form
                  action="https://info.traefik.io/en/request-demo-traefik-enterprise"
                  method="get"
                >
                  <div className="email-field">
                    <input
                      type="email"
                      name="email"
                      placeholder="Your work email"
                    />
                    <div className="email-symbol">
                      <EmailIcon />
                    </div>
                  </div>
                  <input
                    className="button--primary"
                    value="Request a demo"
                    type="submit"
                  />
                </form>
              </Box>
            </Flex>
          </BoxedContainer>

          <div className="home-hero__illustrations">
            <img
              id="illustration-left"
              className="img-left"
              src={heroIllustrationLeft}
              alt=""
            />
            <img className="img-right" src={heroIllustrationRight} alt="" />
          </div>
        </section>

        {/* Latest Posts */}
        {featured_posts.length > 0 && (
          <section className="home-featured-posts" id="home_carousel">
            <div className="home-featured-posts__wrapper">
              <div className="home-featured-posts__posts">
                <div className="splide">
                  <div className="splide__track">
                    <ul className="splide__list">
                      {featured_posts.map(({ node }) => (
                        <li
                          className="splide__slide"
                          key={"Featured__Post__" + node.id}
                        >
                          <HomeFeaturedPost post={node} />
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </section>
        )}

        {/* Customers */}
        <CustomersSection />

        <Flex
          as="section"
          bg="product.hub.bg"
          color="text.inverted.base"
          position="relative"
          minHeight="640px"
          alignItems={["center"]}
          flexDirection={["column", null, null, "row"]}
        >
          <Box
            position="absolute"
            top="0"
            right="0"
            width="100%"
            height={["50%", null, null, "100%"]}
          >
            <StaticImage
              src="../images/home/hub/bg.png"
              alt=""
              layout="fullWidth"
              objectFit="cover"
              style={{
                height: "100%",
              }}
              imgStyle={{
                height: "100%",
              }}
              formats={["auto", "webp", "avif"]}
              placeholder="none"
            />
          </Box>
          <BoxedContainer position="relative" zIndex={1}>
            <Flex
              flexDirection="column"
              alignItems={["center", null, null, "flex-start"]}
              maxWidth={["600px"]}
              py={padding.yaxis.large}
              mx={["auto", null, null, "0"]}
            >
              <Box maxWidth={["170px", null, null, "219px"]}>
                <StaticImage
                  src="../images/home/hub/traefik-hub-beta-logo.png"
                  alt="Traefik Hub Beta"
                  width={219}
                  placeholder="none"
                />
              </Box>
              <Heading
                variant="lg"
                as="h2"
                mt={["32px"]}
                textAlign={["center", null, null, "left"]}
              >
                Your Cloud Native Networking Platform
              </Heading>
              <Flex mt={["32px"]}>
                <Button
                  uppercase
                  bg="product.hub.accent"
                  color="product.hub.bg"
                  hover={{
                    bg: "hsl(68deg, 79%, 47%)",
                    color: theme.colors.product.hub.bg,
                  }}
                  large
                  elementType="link"
                  href="https://hub.traefik.io"
                >
                  Get Started
                </Button>
                <Button
                  uppercase
                  hollow
                  large
                  borderColor="product.hub.accent"
                  color="product.hub.accent"
                  hover={{
                    bg: theme.colors.product.hub.accent,
                    color: theme.colors.product.hub.bg,
                  }}
                  elementType="gatsby_link"
                  to="/traefik-hub/"
                  ml="16px"
                >
                  Learn more
                </Button>
              </Flex>
            </Flex>
          </BoxedContainer>
          <Flex
            position={["relative", null, null, "absolute"]}
            top="0"
            right="0"
            height={["auto", null, null, "100%"]}
            alignItems="center"
            justifyContent="flex-end"
          >
            <Box height="100%" maxHeight={["auto", "auto", "100%"]}>
              <StaticImage
                src="../images/home/hub/traefik-hub-dashboard-illustration.png"
                alt="Traefik hub dashboard"
                width={987}
                objectFit="contain"
                style={{
                  height: "100%",
                  display: "flex",
                }}
                imgStyle={{
                  height: "100%",
                  width: "auto",
                  left: "auto",
                  right: 0,
                }}
                placeholder="none"
                quality={75}
                formats={["auto", "webp", "avif"]}
              />
            </Box>
          </Flex>
        </Flex>

        {/* Stats */}
        <Box
          as="section"
          bg="#f9fafa"
          className="home__stats"
          pt={["60px"]}
          pb={["70px"]}
        >
          <BoxedContainer>
            <Heading
              as="span"
              display="block"
              variant="uppercase"
              textAlign="center"
              mb="40px"
            >
              Powered by an extraordinary community
            </Heading>

            <div className="statistics">
              <div className="statistic">
                <div className="icon">
                  <img src={downloadsIcon} alt="Downloads illustration" />
                </div>
                <div className="content">
                  <div className="title">3 Billion+</div>
                  <div className="sub-title">Downloads</div>
                </div>
              </div>
              <div className="statistic statistic--github">
                <div className="icon">
                  <img src={githubIcon} alt="Github icon" />
                </div>
                <div className="content">
                  <div className="title">35K+</div>
                  <div className="sub-title">Stars on Github</div>
                </div>
              </div>
              <div className="statistic">
                <div className="icon">
                  <img src={contributorsIcon} alt="Contributors illustration" />
                </div>
                <div className="content">
                  <div className="title">600+</div>
                  <div className="sub-title">Contributors</div>
                </div>
              </div>
            </div>
          </BoxedContainer>
        </Box>

        {/* Products */}
        <Box
          as="section"
          className="home-products"
          pt={padding.yaxis.large}
          pb={padding.yaxis.default}
        >
          <BoxedContainer variant="small" textAlign="center">
            <Heading as="h2" variant="md">
              The Traefik ‘Stack’
            </Heading>
            <Paragraph mt={3} variant="md" color="#b3bac0">
              The simplest, most comprehensive cloud-native stack to help
              enterprises manage their entire network across data centers,
              on-premises servers and public clouds all the way out to the edge.
            </Paragraph>
          </BoxedContainer>

          <BoxedContainer>
            {/* Desktop */}
            <div className="home-products-layout home-products-layout--desktop">
              <div id="home-products-details" className="products-details">
                <div className="products-details-wrapper">
                  {/* Enterprise */}
                  <div className="products-details-item products-details-item--traefikee">
                    <div id="product-hook--traefikee"></div>
                    <h3>
                      <img
                        className="logo"
                        src={enterpriseLogo}
                        alt="Kubernetes Ingress + API Gateway Traefik Enterprise"
                      />
                    </h3>
                    <div className="description">
                      All-in-one ingress controller, API management, and service
                      mesh integrated with high availability, advanced security,
                      autoscaling and dedicated support.
                    </div>
                    <Link
                      to="/traefik-enterprise/"
                      className="link--with-arrow link--with-arrow--white"
                    >
                      Discover Traefik Enterprise
                    </Link>
                  </div>

                  {/* Proxy */}
                  <div className="products-details-item products-details-item--traefik">
                    <div id="product-hook--traefik"></div>
                    <h3>
                      <img
                        className="logo"
                        src={proxyLogo}
                        alt="Open Source Load Balancer + Reverse Proxy Traefik"
                      />
                    </h3>
                    <div className="description">
                      The world’s most popular cloud-native application proxy
                      that helps developers and operations teams build, deploy
                      and run modern microservices applications quickly and
                      easily.
                    </div>
                    <Link
                      to="/traefik/"
                      className="link--with-arrow link--with-arrow--white"
                    >
                      Discover Traefik Proxy
                    </Link>
                  </div>

                  {/* Maesh */}
                  <div className="products-details-item products-details-item--maesh">
                    <div id="product-hook--maesh"></div>
                    <h3>
                      <img
                        className="logo"
                        src={meshLogo}
                        alt="Simpler Service Mesh Maesh"
                      />
                    </h3>
                    <div className="description">
                      The simplest and easiest to deploy service mesh for
                      enhanced control, security and observability across all
                      east-west traffic.
                    </div>
                    <Link
                      to="/traefik-mesh/"
                      className="link--with-arrow link--with-arrow--white"
                    >
                      Discover Traefik Mesh
                    </Link>
                  </div>
                </div>
              </div>

              <div id="home-products-images" className="products-images">
                <div className="products-images-wrapper">
                  <StaticImage
                    className="products-images-item products-images-item--traefikee"
                    src="../images/home/products/enterprise.png"
                    alt="Traefik Enterprise illustration"
                    placeholder="blurred"
                    height={565}
                    quality={70}
                  />
                  <StaticImage
                    className="products-images-item products-images-item--traefik"
                    src="../images/home/products/proxy.png"
                    alt="Traefik Proxy illustration"
                    placeholder="blurred"
                    height={565}
                    quality={70}
                  />
                  <StaticImage
                    className="products-images-item products-images-item--maesh"
                    src="../images/home/products/mesh.png"
                    alt="Traefik mesh illustration"
                    placeholder="blurred"
                    height={565}
                    quality={70}
                  />
                </div>
              </div>
            </div>

            {/* Mobile */}
            <div className="home-products-layout home-products-layout--mobile">
              <div
                id="home-products-details--mobile"
                className="products-details"
              >
                <div className="products-details-wrapper">
                  {/* Enterprise */}
                  <div className="products-details-item products-details-item--traefikee">
                    <div id="product-hook--traefikee"></div>
                    <h3>
                      <img
                        className="logo"
                        src={enterpriseLogo}
                        alt="Kubernetes Ingress + API Gateway Traefik Enterprise"
                      />
                    </h3>
                    <div className="description">
                      All-in-one ingress controller, API management, and service
                      mesh integrated with high availability, advanced security,
                      autoscaling and dedicated support.
                    </div>
                    <Link
                      to="/traefik-enterprise/"
                      className="link--with-arrow link--with-arrow--white"
                    >
                      Discover Traefik Enterprise
                    </Link>
                  </div>

                  {/* Proxy */}
                  <div className="products-details-item products-details-item--traefik">
                    <div id="product-hook--traefik"></div>
                    <h3>
                      <img
                        className="logo"
                        src={proxyLogo}
                        alt="Open Source Load Balancer + Reverse Proxy Traefik"
                      />
                    </h3>
                    <div className="description">
                      The world’s most popular cloud-native application proxy
                      that helps developers and operations teams build, deploy
                      and run modern microservices applications quickly and
                      easily.
                    </div>
                    <Link
                      to="/traefik/"
                      className="link--with-arrow link--with-arrow--white"
                    >
                      Discover Traefik Proxy
                    </Link>
                  </div>

                  {/* Maesh */}
                  <div className="products-details-item products-details-item--maesh">
                    <div id="product-hook--maesh"></div>
                    <h3>
                      <img
                        className="logo"
                        src={meshLogo}
                        alt="Simpler Service Mesh"
                      />
                    </h3>
                    <div className="description">
                      The simplest and easiest to deploy service mesh for
                      enhanced control, security and observability across all
                      east-west traffic.
                    </div>
                    <Link
                      to="/traefik-mesh/"
                      className="link--with-arrow link--with-arrow--white"
                    >
                      Discover Traefik Mesh
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </BoxedContainer>
        </Box>

        {/* Solutions */}
        <Box as="section" bg="#f9fafa" py={padding.yaxis.large}>
          <BoxedContainer variant="small" textAlign="center">
            <Heading as="h2" variant="md">
              Simplify your journey to modern cloud-native applications
            </Heading>
            <Paragraph variant="md" mt={3} color="#4e5e6c">
              Traefik Hub is the unified ingress plane for modern, distributed
              applications. It simplifies management and maximizes the
              reliability of cloud native networking services at scale.
            </Paragraph>
          </BoxedContainer>
          <BoxedContainer variant="narrow">
            <Grid
              gridTemplateColumns={["1fr", null, "repeat(2, 1fr)"]}
              gridGap={["24px"]}
              mt={["60px", null, null, "80px"]}
            >
              <CardIconTextLink
                icon={kubernetesIcon}
                heading="Kubernetes Ingress"
                text="A centralized routing solution for your Kubernetes deployment."
                link={{
                  text: "Explore",
                  url: "/solutions/kubernetes-ingress/",
                }}
              />
              <CardIconTextLink
                icon={apiIcon}
                heading="API Gateway"
                text="Act as a single entry point for microservices deployments."
                link={{
                  text: "Explore",
                  url: "/solutions/api-gateway/",
                }}
              />
              <CardIconTextLink
                icon={loadBalancerIcon}
                heading="Modern Load Balancer"
                text="Manage incoming network traffic across your cluster."
                link={{
                  text: "Explore",
                  url: "/solutions/docker-swarm-ingress/",
                }}
              />
              <CardIconTextLink
                icon={serviceMeshIcon}
                heading="Service Mesh"
                text="Monitor and route your internal communications easily."
                link={{
                  text: "Explore",
                  url: "/traefik-mesh/",
                }}
              />
            </Grid>
          </BoxedContainer>
        </Box>

        {/* CTAs */}
        <Box
          as="section"
          bg="background.dark"
          className="home__footer"
          py={padding.yaxis.default}
        >
          <BoxedContainer>
            <Heading as="div" variant="md" textAlign="center" color="#fff">
              Ready to get started?
            </Heading>

            <Flex
              mt="60px"
              alignItems="center"
              justifyContent="center"
              flexWrap="wrap"
            >
              <button
                onClick={() =>
                  (window.location.href =
                    "https://info.traefik.io/en/request-demo-traefik-enterprise")
                }
              >
                <div className="link-text">
                  <span className="text">Request a demo</span>
                  <span className="icon">
                    <RightArrowWhite />
                  </span>
                </div>
                <div className="link-img">
                  <img src={ctaTraefikEEIcon} alt="" />
                </div>
              </button>
              <button
                onClick={() =>
                  (window.location.href =
                    "https://info.traefik.io/contact-sales")
                }
              >
                <div className="link-text">
                  <span className="text">Contact sales</span>
                  <span className="icon">
                    <RightArrowWhite />
                  </span>
                </div>
                <div className="link-img">
                  <img src={ctaEnvelopeIcon} alt="" />
                </div>
              </button>
            </Flex>
          </BoxedContainer>
        </Box>
      </div>
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  {
    featured_posts: allGhostPost(
      filter: {
        tags: { elemMatch: { slug: { eq: "hash-homepage-featured-post" } } }
      }
      sort: { order: DESC, fields: [published_at] }
      limit: 3
    ) {
      edges {
        node {
          ...GhostCarouselCardFields
        }
      }
    }
    featured_posts_1: allGhostPost(
      filter: {
        tags: { elemMatch: { slug: { eq: "hash-homepage-featured-post-1" } } }
      }
      sort: { order: DESC, fields: [published_at] }
      limit: 3
    ) {
      edges {
        node {
          ...GhostCarouselCardFields
        }
      }
    }
    featured_posts_2: allGhostPost(
      filter: {
        tags: { elemMatch: { slug: { eq: "hash-homepage-featured-post-2" } } }
      }
      sort: { order: DESC, fields: [published_at] }
      limit: 3
    ) {
      edges {
        node {
          ...GhostCarouselCardFields
        }
      }
    }
    featured_posts_3: allGhostPost(
      filter: {
        tags: { elemMatch: { slug: { eq: "hash-homepage-featured-post-3" } } }
      }
      sort: { order: DESC, fields: [published_at] }
      limit: 3
    ) {
      edges {
        node {
          ...GhostCarouselCardFields
        }
      }
    }
    latest_blog: allGhostPost(
      filter: { primary_tag: { slug: { eq: "blog" } } }
      sort: { order: DESC, fields: [published_at] }
      limit: 1
    ) {
      edges {
        node {
          ...GhostCarouselCardFields
        }
      }
    }
    latest_resource: allGhostPost(
      filter: { primary_tag: { slug: { eq: "resources" } } }
      sort: { order: DESC, fields: [published_at] }
      limit: 1
    ) {
      edges {
        node {
          ...GhostCarouselCardFields
        }
      }
    }
  }
`

const HomeFeaturedPost = ({ post }) => {
  let eyebrow_text = post?.primary_tag?.name
  let link_text = "Read more"

  let publicTags = useMemo(
    () => post?.tags?.filter(tag => tag?.visibility === "public"),
    [post?.tags]
  )

  switch (post?.primary_tag?.slug) {
    case "press":
      eyebrow_text = "News"
      link_text = "Learn more"
      break
    case "resources":
      eyebrow_text = publicTags?.[1]?.name
      switch (publicTags?.[1]?.slug) {
        case "white-paper":
          link_text = "Read white paper"
          break
        case "datasheet":
          link_text = "Read datasheet"
          break
        case "case-study":
          link_text = "Read case study"
          break
        case "webinar":
          link_text = "Watch webinar"
          break
        case "video":
          link_text = "Watch video"
          break
        default:
          link_text = "Read more"
      }
      break
    default:
      eyebrow_text = post?.primary_tag?.name
  }

  const featuredImage = getImage(post.featureImageSharp)
  const externalLink = getRedirectUrl(post.codeinjection_head)

  const output = (
    <>
      <div className="home-featured-post__image">
        {featuredImage ? (
          <GatsbyImage image={featuredImage} alt={post.title} />
        ) : (
          <img src={post?.feature_image} alt={post.title} />
        )}
      </div>
      <div className="home-featured-post__content">
        {eyebrow_text && (
          <span className="home-featured-post__tag">{eyebrow_text}</span>
        )}
        <h3>{post.title}</h3>
        <div className="link--with-arrow">{link_text}</div>
      </div>
    </>
  )

  return externalLink ? (
    <a
      href={externalLink}
      target="_blank"
      rel="noopener noreferrer"
      className={`home-featured-post home-featured-post--${
        post?.primary_tag?.slug
      } ${!post.feature_image ? "home-featured-post--no-image" : ""}`}
    >
      {output}
    </a>
  ) : (
    <Link
      to={`/${post?.primary_tag?.slug}/${post.slug}/`}
      className={`home-featured-post home-featured-post--${
        post?.primary_tag?.slug
      } ${!post.feature_image ? "home-featured-post--no-image" : ""}`}
    >
      {output}
    </Link>
  )
}
