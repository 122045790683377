import React from "react"
import styled from "styled-components"
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"

import { Section, BoxedContainer, Heading } from "../../elements"
import theme from "../../styles/theme"

const CustomersSection = () => (
  <Section.Container bg="#f9fafa" withPadding>
    <BoxedContainer>
      <Heading m="0 auto 40px" variant="uppercase" as="h2" textAlign="center">
        Loved &amp; Trusted by Leading Enterprises Worldwide
      </Heading>

      <Customers>
        <StaticImage
          src="../../images/home/customers/gartner-cool-vendor-2021.png"
          alt="Gartner Cool Vendor 2021"
          width={192}
          placeholder="blurred"
          style={{
            gridColumn: "1/3",
            gridRow: "1/3",
          }}
        />

        <StaticImage
          src="../../images/home/customers/mozilla.png"
          alt="Mozilla"
          width={113}
          placeholder="blurred"
        />

        <StaticImage
          src="../../images/home/customers/ebay.png"
          alt="Ebay"
          width={113}
          placeholder="blurred"
        />

        <StaticImage
          src="../../images/home/customers/customer-logos-expedia.png"
          alt="Expedia"
          width={113}
          placeholder="blurred"
        />

        <StaticImage
          src="../../images/home/customers/customer-logos-ovh-cloud.png"
          alt="OVHcloud"
          width={113}
          placeholder="blurred"
        />

        <StaticImage
          src="../../images/home/customers/customer-logos-sysdig.png"
          alt="sysdig"
          width={113}
          placeholder="blurred"
        />

        <StaticImage
          src="../../images/home/customers/customer-logos-talend.png"
          alt="Talend"
          width={113}
          placeholder="blurred"
        />

        <StaticImage
          src="../../images/home/customers/customer-logos-bose.png"
          alt="Bose"
          width={113}
          placeholder="blurred"
        />

        <StaticImage
          src="../../images/home/customers/customer-logos-cisco.png"
          alt="Cisco"
          width={113}
          placeholder="blurred"
        />

        <StaticImage
          src="../../images/home/customers/customer-logos-adeo.png"
          alt="adeo"
          width={113}
          placeholder="blurred"
        />

        <StaticImage
          src="../../images/home/customers/customer-logos-rtbf.png"
          alt="rtbf"
          width={113}
          placeholder="blurred"
        />

        <StaticImage
          src="../../images/home/customers/customer-logos-nasa.png"
          alt="nasa"
          width={113}
          placeholder="blurred"
        />

        <StaticImage
          src="../../images/home/customers/customer-logos-dupont.png"
          alt="dupont"
          width={113}
          placeholder="blurred"
        />

        <StaticImage
          src="../../images/home/customers/customer-logos-carfax.png"
          alt="carfax"
          width={113}
          placeholder="blurred"
        />

        <StaticImage
          src="../../images/home/customers/customer-logos-ionos.png"
          alt="ionos"
          width={113}
          placeholder="blurred"
        />
      </Customers>

      <Section.CTAs>
        <Link
          to="/success-stories/"
          className="button--secondary button--secondary--with-arrow"
        >
          Success Stories
        </Link>
      </Section.CTAs>
    </BoxedContainer>
  </Section.Container>
)

export default CustomersSection

const Customers = styled.div`
  display: grid;
  grid-template-columns: repeat(9, auto);
  align-items: center;
  justify-items: center;
  grid-gap: 0 30px;

  @media (max-width: ${theme.breakpointsObj.xl}) {
    grid-gap: 0 20px;
  }

  @media (max-width: ${theme.breakpointsObj.md}) {
    grid-template-columns: repeat(6, auto);
    grid-gap: 20px 25px;
  }

  @media (max-width: ${theme.breakpointsObj.sm}) {
    grid-template-columns: repeat(3, auto);
    grid-gap: 25px 30px;
  }
`
