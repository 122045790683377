import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"

import { Box, Image, Heading, Paragraph, StyledLink } from "../elements"

type CardIconTextLinkProps = {
  icon: string
  heading: string
  text: string
  link: {
    text: string
    url: string
  }
}

const CardIconTextLink: React.FC<CardIconTextLinkProps> = ({
  icon,
  heading,
  text,
  link,
}) => (
  <Box
    p={["30px", null, null, "48px"]}
    bg="#fff"
    border="1px solid #ebeaeb"
    borderRadius="6px"
    boxShadow="0 6px 12px 0 rgba(31, 3, 35, 0.03)"
    position="relative"
  >
    <Image src={icon} alt={`Icon for ${heading}`} />
    <Heading as="h3" variant="sm" mt="24px">
      {heading}
    </Heading>
    <Paragraph variant="sm" mt="16px" color="text.base">
      {text}
    </Paragraph>
    <StyledLink
      icon="arrow"
      to={link.url}
      color="#03192d"
      mt="24px"
      position="relative"
      zIndex={1}
    >
      {link.text}
    </StyledLink>
    <InvisibleLink to={link.url} tabIndex={-1} />
  </Box>
)

const InvisibleLink = styled(Link)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`

export default CardIconTextLink
